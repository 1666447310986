

.paymentColumn{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.paymentContainer{
    width: 100%;
    font-size: 45px;
    color: #d0d0d0;
    height: 83%;
}
