@tailwind base;
@tailwind components;
@tailwind utilities;

.slider-sizes {
  width: 90%;
  height: 100%;
}
* {
  font-family: 'Poppins', sans-serif;
}
.slick-dots {
  height: 20%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow-x: scroll;
}

.link-comp {
  width: 100%;
  height: 100%;
  display: flex;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.payment-slider {
  height: 100% !important;
  overflow: auto;
}

html,
body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
}

#root {
  height: 100%;
  z-index: -10;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'popins',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .css-jts8na {
  object-fit: contain !important;
  height: auto !important;
} */
